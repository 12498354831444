export const rgbToHex = (rgbColorArray) => {
    return `#${rgbColorArray[0].toString(16).padStart(2, '0')}${rgbColorArray[1]
        .toString(16)
        .padStart(2, '0')}${rgbColorArray[2].toString(16).padStart(2, '0')}`;
}

export const hexToRgb = (hex) => {
    hex = hex.replace(/^#/, '');
	const number = Number.parseInt(hex, 16);
	const red = number >> 16;
	const green = (number >> 8) & 255;
	const blue = number & 255;

	return [red, green, blue];
}