import { numberFormatter } from './formatter';
import { colorMap } from './colors';

export const histogramDefaults = (theme, colors, slots) => {
    const nth = 10 / slots;
    const range = colorMap[colors](theme, nth);
    const collection = [];
    for (let i = 0; i < range.length; i++) {
        collection.push({ value: 0, color: range[i] });
    }
    return collection;
};

export const legendDefaults = (theme, colors, slots, categories) => {
    const nth = 10 / slots;
    const range = colorMap[colors](theme, nth);
    const collection = [];
    for (let i = 0; i < range.length; i++) {
        collection.push({ label: categories[i], color: range[i] });
    }
    return collection;
};

export const legendSetAmount = (theme, colors, categories) => {
    const range = colorMap[colors](theme, 1);
    const collection = [];
    for (let i = 0; i < range.length; i++) {
        collection.push({ label: categories[i], color: range[i] });
    }
    return collection;
};

export const setRangeCategories = function (ranges, exceedMin, exceedMax, abbreviate) {
    const collection = [];
    for (let i = 0; i < ranges.length; i++) {
        const [low, high] = ranges[i];
        let label;
        if (exceedMin && i === 0) {
            label = `< ${abbreviate ? numberFormatter(high) : high}`;
        } else if (exceedMax && i === ranges.length - 1) {
            label = `${abbreviate ? numberFormatter(low) : low}+`;
        } else {
            label = `${abbreviate ? numberFormatter(low) : low}-${
                abbreviate ? numberFormatter(high) : high
            }`;
        }

        collection.push(label);
    }
    return collection;
};
