import { useRoutes } from 'react-router-dom';
import {
    createTheme,
    CssBaseline,
    Grid,
    makeStyles,
    responsiveFontSizes,
    ThemeProvider,
} from '@material-ui/core';
import { cartoThemeOptions } from '@carto/react-ui';
import { merge } from 'lodash';
import routes from './routes';
import Header from 'components/common/Header';
import 'assets/css/rc-slider.css';
import 'assets/css/styles.css';
const customPalette = {
    palette: {
        primary: {
            main: '#242d64',
        },
        secondary: {
            main: '#33657f',
        },
        background: {
            default: '#f6f6f6',
        },
        actions: {
            active: 'rgba(44, 48, 50, 0.2)',
        },
        text: {
            secondary: '#4A5568',
        },
        compliant: '#84BD00',
        inProgress: '#FBBF24',
        notCompliant: '#9E1316',
        exempt: '#000000',
        excluded: '#868d91',
        scale: {
            1: '#195905',
            2: '#4D7C0F',
            3: '#84BD00',
            4: '#A3E635',
            5: '#FDE047',
            6: '#FCD34D',
            7: '#FBBF24',
            8: '#F59E0B',
            9: '#D97706',
            10: '#EA580C',
        },
        neutral: {
            1: '#c7e7fd',
            2: '#9dd4fc',
            3: '#73c2fb',
            4: '#68afe2',
            5: '#5692bc',
            6: '#4d789e',
            7: '#385f7b',
            8: '#003e74',
            9: '#002f57',
            10: '#001428',
        },
    },
};

const merged = merge(cartoThemeOptions, customPalette);

let theme = createTheme(merged);
theme = responsiveFontSizes(theme, {
    breakpoints: cartoThemeOptions.breakpoints.keys,
    disableAlign: false,
    factor: 2,
    variants: [
        'h1',
        'h2',
        'h3',
        'h4',
        'h5',
        'h6',
        'subtitle1',
        'subtitle2',
        'body1',
        'body2',
        'button',
        'caption',
        'overline',
    ],
});

const useStyles = makeStyles(() => ({
    root: {
        flex: 1,
        overflow: 'hidden',
    },
}));

function App() {
    const classes = useStyles();
    const routing = useRoutes(routes);
    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <Grid container direction='column' className={classes.root}>
                <>
                    <Header />
                    {routing}
                </>
            </Grid>
        </ThemeProvider>
    );
}

export default App;
