import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { addLayer, removeLayer, setViewState } from '@carto/react-redux';
import { Outlet } from 'react-router-dom';
import { BUILDINGS_LAYER_ID } from 'components/layers/BuildingsLayer';
import { CITY_LIMITS_LAYER_ID } from 'components/layers/CityLimitsLayer';

function Buildings() {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(
            setViewState({
                latitude: 39.7426846,
                longitude: -104.8281907,
                zoom: 10.5,
                transitionDuration: 500,
            })
        );
    }, [dispatch]);

    useEffect(() => {
        dispatch(
            addLayer({
                id: CITY_LIMITS_LAYER_ID,
                source: null,
            })
        );

        dispatch(
            addLayer({
                id: BUILDINGS_LAYER_ID,
                source: null,
                layerAttributes: {
                    legend: null,
                },
            })
        );

        return () => {
            dispatch(removeLayer(BUILDINGS_LAYER_ID));
            dispatch(removeLayer(CITY_LIMITS_LAYER_ID));
        };
    }, [dispatch]);

    return <Outlet />;
}

export default Buildings;
