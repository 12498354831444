import { InputBase, withStyles } from '@material-ui/core';

const TouchstoneInput = withStyles((theme) => ({
    root: {
        'label + &': {
            marginTop: theme.spacing(3.5),
        },
    },
    input: {
        borderRadius: theme.shape.borderRadius,
        position: 'relative',
        backgroundColor: theme.palette.background.paper,
        border: '1px solid #ddd',
        fontSize: 16,
        padding: '8px 26px 8px 12px',
        transition: theme.transitions.create(['border-color', 'box-shadow']),
        '&:focus': {
            borderRadius: theme.shape.borderRadius,
            borderColor: '#ddd',
            boxShadow: '0 0 0 0.2rem rgba(44, 48, 50, 0.12)',
        },
    },
}))(InputBase);

export default TouchstoneInput;
