import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setDataFilters, setError } from 'config/appSlice';
import {
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    makeStyles,
} from '@material-ui/core';
import TouchstoneInput from 'components/common/TouchstoneInput';
import { primary_use_type } from 'config/defaultValues';
import ApiService from 'data/apiService';
import { hasOwn } from 'utils/checkValue';
import useIsMounted from 'utils/useIsMounted';
import { capitalize } from 'utils/formatter';
import { chain } from 'lodash';

const defaultValues = primary_use_type();
const KEY = defaultValues.key;

const useStyles = makeStyles((theme) => ({
    count: {
        color: theme.palette.grey[200],
    },
}));

function PropertyTypeUngrouped() {
    const dispatch = useDispatch();
    const isMounted = useIsMounted();
    const classes = useStyles();
    const data = useSelector((state) => state.app.data);
    const selected = useSelector((state) => state.app.filters[KEY].values);
    const [usetypes, setUsetypes] = useState([]);
    const [categories, setCategories] = useState({});

    useEffect(() => {
        const api = new ApiService().map();
        const fetchData = async () => {
            try {
                const { data } = await api.getUseTypes(false);
                let collection = [];
                if (data) {
                    collection = data;
                }
                if (isMounted.current) setUsetypes(collection);
            } catch (error) {
                dispatch(setError(error.message));
            }
        };

        fetchData();
    }, [isMounted, dispatch]);

    useEffect(() => {
        let categories = {};
        if (data && data.length > 0) {
            categories = chain(data)
                .filter((t) => t[KEY] != null)
                .countBy(KEY)
                .value();
        }
        setCategories(categories);
    }, [data]);

    const handleCount = (key) => {
        if (hasOwn(categories, key)) return categories[key];
        else return 0;
    };

    const handleChange = (event) => {
        dispatch(setDataFilters({ key: KEY, value: event.target.value }));
    };

    return (
        <FormControl>
            <InputLabel id='select-propery-type-label' shrink>
                Property Type
            </InputLabel>
            <Select
                labelId='select-propery-type-label'
                displayEmpty
                multiple
                value={selected}
                onChange={handleChange}
                input={<TouchstoneInput />}
                renderValue={(selected) => {
                    if (selected.length === 0) {
                        return <span>All Property Types</span>;
                    }
                    return selected.map((s) => capitalize(s)).join(', ');
                }}
            >
                {usetypes &&
                    usetypes.map((s) => (
                        <MenuItem key={s.id} value={s.label}>
                            {capitalize(s.label)} &nbsp;
                            {handleCount(s.label) > 0 && (
                                <span className={classes.count}>
                                    ({handleCount(s.label)})
                                </span>
                            )}
                        </MenuItem>
                    ))}
            </Select>
        </FormControl>
    );
}

export default PropertyTypeUngrouped;
