function removeEvery(array, nth) {
    if (nth <= 1) return array;
    let j = 0;

    for (var i = 0; i < array.length; i++) {
        if ((i + 1) % nth) array[j++] = array[i];
    }
    array.length = j;

    return array;
}

export const goodToBad = (theme, nth = 1) => {
    return removeEvery(Object.values(theme.palette.scale), nth);
};

export const badToGood = (theme, nth = 1) => [...goodToBad(theme, nth)].reverse();

export const neutral = (theme, nth = 1) => {
    return removeEvery(Object.values(theme.palette.neutral), nth);
};

export const exempt = (theme) => {
    return theme.palette.exempt;
};

export const notCompliant = (theme) => {
    return theme.palette.notCompliant;
};

export const excluded = (theme) => {
    return theme.palette.excluded;
};

export const status = (theme) => {
    return [
        theme.palette.compliant,
        theme.palette.notCompliant,
        theme.palette.exempt,
    ];
};

export const colorMap = {
    badToGood,
    goodToBad,
    neutral,
    status,
};
