import { POSITRON } from '@carto/react-basemaps';

export const initialState = {
    viewState: {
        latitude: 39.76453,
        longitude: -105.1353107,
        zoom: 2,
        pitch: 0,
        bearing: 0,
        dragRotate: false,
    },
    basemap: POSITRON,
    credentials: {
        username: 'touchstone',
        apiKey: 'default_public',
        serverUrlTemplate: 'https://{user}.carto.com',
    },
    googleApiKey: '', // only required when using a Google Basemap
};

export const oauthInitialState = {
    token: null,
};
