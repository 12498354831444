import { makeStyles } from '@material-ui/core';
import { Paper, Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    wrapper: {
        maxWidth: '550px',
        padding: theme.spacing(2),
    },
    paper: {
        padding: theme.spacing(2),
        marginBottom: theme.spacing(2),
    },
    title: {
        textAlign: 'center',
        marginBottom: theme.spacing(2),
    },
    subTitle: {
        marginTop: theme.spacing(1),
    },
    number: {
        color: '#ffffff',
        backgroundColor: theme.palette.grey[500],
    },
}));

function BeyondBenchmarking() {
    const classes = useStyles();

    return (
        <section className={classes.wrapper}>
            <Typography
                variant='h6'
                component='h1'
                className={classes.title}
                gutterBottom
            >
                Additional FREE Resources to Improve
            </Typography>
            <Paper variant='outlined' className={classes.paper}></Paper>
        </section>
    );
}

export default BeyondBenchmarking;
