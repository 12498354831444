import CityLimitsLayer from './CityLimitsLayer';
import BuildingsLayer from './BuildingsLayer';
// Auto import

export const getLayers = () => {
    return [
        CityLimitsLayer(),
        BuildingsLayer(),
        // Auto import layers
    ];
};
