import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setDataFilters, setLegend } from 'config/appSlice';
import PieWidgetUI from 'components/widgets/PieWidgetUI';
import {
    Grid,
    IconButton,
    Tooltip,
    Typography,
    FormHelperText,
    makeStyles,
    useTheme,
} from '@material-ui/core';
import { compliance_status } from 'config/defaultValues';
import { status } from 'utils/colors';
import { isEmpty, hasOwn } from 'utils/checkValue';
import checkNotReportingYear from 'utils/checkNotReportingYear';
import { legendSetAmount } from 'utils/setChartDefaults';
import InfoIcon from '@material-ui/icons/Info';
import filterData from 'utils/filterData';
import { capitalize } from 'utils/formatter';
import { chain } from 'lodash';

const useStyles = makeStyles((theme) => ({
    title: {
        padding: theme.spacing(1, 0, 1),
        display: 'block',
    },
    help: {
        padding: theme.spacing(1, 0, 0),
    },
}));

const defaultValues = compliance_status();
const KEY = defaultValues.key;
const COLORS = defaultValues.colors;
const RANGES = defaultValues.ranges;
const LABELS = defaultValues.labels;

function ComplianceStatus() {
    const theme = useTheme();
    const classes = useStyles();
    const dispatch = useDispatch();

    const data = useSelector((state) => state.app.data);
    const filters = useSelector((state) => state.app.filters);
    const selectedFilter = useSelector((state) => state.app.selectedFilter);
    const selectedYear = useSelector((state) => state.app.selectedYear);
    const selected = useSelector((state) => state.app.filters[KEY].values);

    const [categories, setCategories] = useState([]);
    const [colors, setColors] = useState([]);
    const [totalCompliance, setTotalCompliance] = useState(0);
    const [labelOverride, setLabelOverride] = useState('notReportingPeriod');

    useEffect(() => {
        setLabelOverride(checkNotReportingYear(selectedYear) ? 'notReportingPeriod' : 'reportingPeriod');
    }, [selectedYear])

    useEffect(() => {
        const options = status(theme);
        const categories = [];
        const colors = [];
        let count = {};
        if (data && data.length > 0) {
            const filteredData = filterData(data, filters, [KEY]);
            count = chain(filteredData)
                .filter((t) => t[KEY] != null)
                .countBy(KEY)
                .value();
            for (let i = 0; i < RANGES.length; i++) {
                const key = RANGES[i];
                if (hasOwn(count, key)) {
                    let label = capitalize(key);
                    if (hasOwn(LABELS[labelOverride], key)) label = capitalize(LABELS[labelOverride][key]);
                    categories.push({ name: label, value: count[key], identifier: key });
                    colors.push(options[i]);
                }
            }
        }

        const compliance = ['in compliance', 'exempt'];
        let total = 0;
        for (let i = 0; i < compliance.length; i++) {
            const key = compliance[i];
            if (hasOwn(count, key)) {
                total += count[key];
            }
        }
        setTotalCompliance(total);

        setColors(colors);
        setCategories(categories);
    }, [theme, data, filters, labelOverride]);

    useEffect(() => {
        if (selectedFilter === KEY) {
            
            const labels = [...RANGES];
            if (!isEmpty(LABELS[labelOverride])) {
                for (let i = 0; i < RANGES.length; i++) {
                    const key = RANGES[i];
                    if (hasOwn(LABELS[labelOverride], key)) {
                        labels.splice(i, 1, LABELS[labelOverride][key])
                    }
                }
            }

            dispatch(
                setLegend({
                    title: 'Benchmarking Compliance Status',
                    categories: legendSetAmount(theme, COLORS, labels),
                })
            );
        }
    }, [dispatch, selectedFilter, theme, labelOverride]);

    const onSelectedCategoriesChange = (categories,test) => {
        const values = categories.map((c) => c.toLowerCase());
        dispatch(setDataFilters({ key: KEY, value: values }));
    };

    return (
        <>
            <Grid container alignItems={'center'} justifyContent={'space-between'}>
                <Typography
                    component='h5'
                    color='textSecondary'
                    variant='subtitle2'
                    className={classes.title}
                >
                    Covered Buildings
                </Typography>
                <Tooltip
                    title='Compliance refers to the building’s compliance status with the Energize Denver Benchmarking program for the selected year. Learn more about the Energize Denver Benchmarking program by clicking the information icon.'
                    placement='top'
                >
                    <IconButton
                        aria-label='Covered Buildings Info'
                        size='small'
                        color='primary'
                        href='https://www.denvergov.org/Government/Agencies-Departments-Offices/Agencies-Departments-Offices-Directory/Climate-Action-Sustainability-Resiliency/Goals-Policy/Energize-Denver-Benchmarking'
                        target='_blank'
                        rel='noopener noreferrer'
                    >
                        <InfoIcon fontSize='small' />
                    </IconButton>
                </Tooltip>
            </Grid>
            <PieWidgetUI
                id='buildingComplianceStatus'
                data={categories}
                colors={colors}
                selectedCategories={selected}
                onSelectedCategoriesChange={onSelectedCategoriesChange}
            />
            <FormHelperText className={classes.help}>
                * The total number of buildings In Compliance is {totalCompliance} which
                is the sum of the 'Exempt' and 'In Compliance' covered buildings.
            </FormHelperText>
        </>
    );
}

export default ComplianceStatus;
