import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setDataFilters, setLegend } from 'config/appSlice';
import HistogramWidgetUI from 'components/widgets/HistogramWidgetUI';
import {
    Grid,
    IconButton,
    Tooltip,
    Typography,
    makeStyles,
    useTheme,
} from '@material-ui/core';
import { Range, createSliderWithTooltip } from 'rc-slider';
import useDebounce from 'utils/debounce';
import { site_eui } from 'config/defaultValues';
import { isEmpty } from 'utils/checkValue';
import {
    histogramDefaults,
    setRangeCategories,
    legendDefaults,
} from 'utils/setChartDefaults';
import InfoIcon from '@material-ui/icons/Info';
import filterData from 'utils/filterData';
import { chain } from 'lodash';

const defaultValues = site_eui();
const KEY = defaultValues.key;
const SLOTS = defaultValues.slots;
const MIN = defaultValues.min;
const MAX = defaultValues.max;
const EXCEEDMIN = defaultValues.exceedMin;
const EXCEEDMAX = defaultValues.exceedMax;
const COLORS = defaultValues.colors;
const RANGES = defaultValues.ranges;

const useStyles = makeStyles((theme) => ({
    title: {
        padding: theme.spacing(1, 0, 1),
        display: 'block',
    },
    slider: {
        margin: theme.spacing(1, 0, 1),
    },
    accordion: {
        marginLeft: -theme.spacing(2),
        marginRight: -theme.spacing(2),
    },
}));

const RangeWithTooltip = createSliderWithTooltip(Range);

function SiteEUI() {
    const theme = useTheme();
    const classes = useStyles();
    const dispatch = useDispatch();

    const data = useSelector((state) => state.app.data);
    const filters = useSelector((state) => state.app.filters);
    const selectedFilter = useSelector((state) => state.app.selectedFilter);

    const [values, setValues] = useState([MIN, MAX]);
    const [series, setSeries] = useState([]);

    const axis = setRangeCategories(RANGES, EXCEEDMIN, EXCEEDMAX);

    useEffect(() => {
        let series = [];
        if (data && data.length > 0) {
            const filteredData = filterData(data, filters, [KEY]);
            series = chain(filteredData)
                .filter((t) => t[KEY] != null && t.status === 'in compliance')
                .reduce((arry, e) => {
                    const value = e[KEY];
                    for (let i = 0; i < RANGES.length; i++) {
                        const [low, high] = RANGES[i];
                        if (
                            (value >= low && value < high + 1) ||
                            (value < MIN && low === MIN && EXCEEDMIN) ||
                            (value > MAX && high === MAX && EXCEEDMAX)
                        ) {
                            arry[i].value++;
                            continue;
                        }
                    }
                    return arry;
                }, histogramDefaults(theme, COLORS, SLOTS))
                .value();
        }
        setSeries(series);
    }, [theme, data, filters]);

    useEffect(() => {
        if (selectedFilter === KEY) {
            dispatch(
                setLegend({
                    title: 'Site Energy Use Intensity (kBtu/ft<sup>2</sup>)',
                    categories: legendDefaults(theme, COLORS, SLOTS, axis),
                    showNotAvailable: true,
                })
            );
        }
    }, [dispatch, selectedFilter, axis, theme]);

    const handleFormat = (val) => {
        const data = val[0];
        return `
            Site EUI (kBtu/ft<sup>2</sup>)<br /> 
            ${data.name}: ${data.value} properties
        `;
    };

    const invokeDebounced = useDebounce(
        () => dispatch(setDataFilters({ key: KEY, value: values })),
        1000
    );
    useEffect(invokeDebounced, [values, invokeDebounced]);

    const handleChange = (newValue) => {
        if (!isEmpty(newValue)) setValues(newValue);
    };

    const valueLabelFormat = (val) => {
        let retval = val;
        if (val === MIN && EXCEEDMIN) retval = `< ${retval}`;
        if (val === MAX && EXCEEDMAX) retval += '+';
        return retval;
    };

    const ariaValueText = (val) => {
        return `${val}`;
    };

    return (
        <>
            <Grid container alignItems={'center'} justifyContent={'space-between'}>
                <Typography
                    component='h5'
                    color='textSecondary'
                    variant='subtitle2'
                    className={classes.title}
                >
                    Site Energy Use Intensity (kBtu/ft<sup>2</sup>)
                </Typography>
                <Tooltip
                    title='Energy Use Intensity (EUI) is expressed as energy consumed (measured in kBtu) per square foot per year. Learn more about EUI by clicking the information Icon.'
                    placement='top'
                >
                    <IconButton
                        aria-label='EUI Info'
                        size='small'
                        color='primary'
                        href='https://www.energystar.gov/buildings/benchmark/understand_metrics/what_eui'
                        target='_blank'
                        rel='noopener noreferrer'
                    >
                        <InfoIcon fontSize='small' />
                    </IconButton>
                </Tooltip>
            </Grid>
            <HistogramWidgetUI
                id='buildingSiteEUI'
                name='site_eui'
                data={series}
                dataAxis={axis}
                isLoading={series.length === 0}
                height={100}
                tooltipFormatter={handleFormat}
            />
            <RangeWithTooltip
                value={values}
                ariaLabelGroupForHandles={['Min EUI', 'Max EUI']}
                ariaValueTextFormatterGroupForHandles={ariaValueText}
                onChange={handleChange}
                min={MIN}
                max={MAX}
                tipFormatter={valueLabelFormat}
                className={classes.slider}
            />
        </>
    );
}

export default SiteEUI;
